// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';


.card-body{
  margin-top: 10px;
  margin-bottom: 30px;
}



// @font-face {
//   font-family: 'ElMessiri';
//   src: url("../fonts/ElMessiri-Regular.ttf");
//   font-weight: normal;
//   font-style: normal
// }

// @font-face {
//   font-family: 'Kanit';
//   src: url("../fonts/Kanit-Light.ttf");
//   font-weight: normal;
//   font-style: normal
// }

// body {
//   font-size: 16px;
//   font-family: "Prompt",sans-serif,"Comfortaa",cursive;
//   font-weight: 300;
//   margin: 0;
//   height: 100%;
//   background-color: #EEE
// }

.btn-primary {
  background-color: #126AA7;
  color: #fff;
  border: 1px solid #126AA7;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary:active:hover {
  background-color: #0e5382;
  border-color: #0e5382;
  color: #fff;
  outline: none
}

.btn-primary.btn-outline {
  background-color: transparent;
  border-color: #126AA7;
  color: #126AA7;
  border-radius: 50px 50px 50px 50px !important
}

.btn-primary.btn-outline:hover,.btn-primary.btn-outline:focus,.btn-primary.btn-outline:active {
  background-color: #0e5382;
  color: #fff
}

.btn-primary.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-primary.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-primary.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-primary.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-primary.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-success {
  background-color: #4caf50;
  color: #fff;
  border: 1px solid #4caf50;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-success:hover,.btn-success:focus,.btn-success:active,.btn-success:active:hover {
  background-color: #409343;
  border-color: #409343;
  color: #fff;
  outline: none
}

.btn-success.btn-outline {
  background-color: transparent;
  border-color: #4caf50;
  color: #4caf50;
  border-radius: 50px 50px 50px 50px !important
}

.btn-success.btn-outline:hover,.btn-success.btn-outline:focus,.btn-success.btn-outline:active {
  background-color: #409343;
  color: #fff
}

.btn-success.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-success.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-success.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-success.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-success.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-info {
  background-color: #2D8DFC;
  color: #fff;
  border: 1px solid #2D8DFC;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-info:hover,.btn-info:focus,.btn-info:active,.btn-info:active:hover {
  background-color: #0577fb;
  border-color: #0577fb;
  color: #fff;
  outline: none
}

.btn-info.btn-outline {
  background-color: transparent;
  border-color: #2D8DFC;
  color: #2D8DFC;
  border-radius: 50px 50px 50px 50px !important
}

.btn-info.btn-outline:hover,.btn-info.btn-outline:focus,.btn-info.btn-outline:active {
  background-color: #0577fb;
  color: #fff
}

.btn-info.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-info.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-info.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-info.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-info.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-warning {
  background-color: #FA680A;
  color: #fff;
  border: 1px solid #FA680A;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-warning:hover,.btn-warning:focus,.btn-warning:active,.btn-warning:active:hover {
  background-color: #d75704;
  border-color: #d75704;
  color: #fff;
  outline: none
}

.btn-warning.btn-outline {
  background-color: transparent;
  border-color: #FA680A;
  color: #FA680A;
  border-radius: 50px 50px 50px 50px !important
}

.btn-warning.btn-outline:hover,.btn-warning.btn-outline:focus,.btn-warning.btn-outline:active {
  background-color: #d75704;
  color: #fff
}

.btn-warning.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-warning.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-warning.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-warning.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-warning.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-danger {
  background-color: #D90404;
  color: #fff;
  border: 1px solid #D90404;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-danger:hover,.btn-danger:focus,.btn-danger:active,.btn-danger:active:hover {
  background-color: #b10303;
  border-color: #b10303;
  color: #fff;
  outline: none
}

.btn-danger.btn-outline {
  background-color: transparent;
  border-color: #D90404;
  color: #D90404;
  border-radius: 50px 50px 50px 50px !important
}

.btn-danger.btn-outline:hover,.btn-danger.btn-outline:focus,.btn-danger.btn-outline:active {
  background-color: #b10303;
  color: #fff
}

.btn-danger.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-danger.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-danger.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-danger.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-danger.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-darken {
  background-color: #2A2A2A;
  color: #fff;
  border: 1px solid #2A2A2A;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-darken:hover,.btn-darken:focus,.btn-darken:active,.btn-darken:active:hover {
  background-color: #161616;
  border-color: #161616;
  color: #fff;
  outline: none
}

.btn-darken.btn-outline {
  background-color: transparent;
  border-color: #2A2A2A;
  color: #2A2A2A;
  border-radius: 50px 50px 50px 50px !important
}

.btn-darken.btn-outline:hover,.btn-darken.btn-outline:focus,.btn-darken.btn-outline:active {
  background-color: #161616;
  color: #fff
}

.btn-darken.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-darken.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-darken.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-darken.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-darken.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.btn-default {
  background-color: #ddd;
  color: #fff;
  border: 1px solid #ddd;
  text-transform: capitalize;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out
}

.btn-default:hover,.btn-default:focus,.btn-default:active,.btn-default:active:hover {
  background-color: #c9c9c9;
  border-color: #c9c9c9;
  color: #fff;
  outline: none
}

.btn-default.btn-outline {
  background-color: transparent;
  border-color: #ddd;
  color: #ddd;
  border-radius: 50px 50px 50px 50px !important
}

.btn-default.btn-outline:hover,.btn-default.btn-outline:focus,.btn-default.btn-outline:active {
  background-color: #c9c9c9;
  color: #fff
}

.btn-default.group-left {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important
}

.btn-default.group-right {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important
}

.btn-default.btn-lg {
  padding: 10px 16px;
  font-size: 18px
}

.btn-default.btn-sm {
  padding: 5px 10px;
  font-size: 12px
}

.btn-default.btn-xs {
  padding: 1px 5px;
  font-size: 12px
}

.text-default {
  color: #2A2A2A
}

.text-primary {
  color: #126AA7
}

.text-success {
  color: #4caf50
}

.text-danger {
  color: #D90404
}

.no-padding {
  padding-left: 0;
  padding-right: 0
}

.input-group input{
  width: 100%;
  height: 45px;
  background: #DDD;
  border-color: transparent;
  // box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 6px 12px;
  padding-left: 10px;
  font-size: 14px;
  // border-radius: 50px 50px 50px 50px !important;
  // -webkit-transition: all 0.2s linear;
  // -moz-transition: all 0.2s linear;
  // -ms-transition: all 0.2s linear;
  // transition: all 0.2s linear;
  // transition-property: border, background, color, box-shadow, padding;
  // -webkit-transition-property: border, background, color, box-shadow, padding;
  // -webkit-appearance: none
}

.has-error input{
  border-color: #f86c6b;
}
input[type="text"]:focus,input[type="password"]:focus {
  outline: 0
}

.radio-custom {
  padding-left: 20px
}

.radio-custom input[type=radio] {
  width: 25px;
  height: 25px;
  opacity: 0;
  z-index: 1
}

.radio-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 40px
}

.radio-custom input[type=radio]:checked+label::before {
  border-color: #DDD;
  border-width: 10px
}

.radio-primary input[type=radio]:checked+label::before {
  border-color: #126AA7
}

.radio-primary input[type=radio]:checked+label::after {
  border-color: #fff
}

.radio-custom input[type=radio]:checked+label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1)
}

.radio-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 25px;
  height: 25px;
  left: 0;
  border: 1px solid #e4eaec;
  border-radius: 50%;
  background-color: #DDD;
  -webkit-transition: border .3s ease-in-out 0s, color .3s ease-in-out 0s;
  -o-transition: border .3s ease-in-out 0s, color .3s ease-in-out 0s;
  transition: border .3s ease-in-out 0s, color .3s ease-in-out 0s
}

.checkbox-custom,.radio-custom {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px
}

.checkbox-custom input[type=checkbox],.radio-custom input[type=radio] {
  position: absolute;
  margin-left: 0px;
  margin-top: 0;
  margin-bottom: 0
}

.checkbox-custom label,.radio-custom label {
  min-height: 22px;
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer
}

.wrapper {
  position: relative;
  height: 100vh
}

.navbar {
  // height: 70px;
  // border: none;
  // z-index: 10
}

.navbar.bg-primary {
  background-color: #126AA7 !important;
  -webkit-box-shadow: 0 8px 20px 0 rgba(84,84,120,0.1);
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.46);
  // border-radius: 0px
}

.navbar .navbar-header {
  width: 100%
}

.navbar .navbar-header .navbar-brand {
  float: none;
  /* padding: 110px; */
  padding: 0px;
  width: 100%;
  margin-top: 25px;
}


.navbar .navbar-header .navbar-brand .logo-smt {
  position: relative;
  height: 45px;
  margin: auto;
  vertical-align: middle;
display: block;
  top: -10px
}

#login {
  position: relative;
  padding-top: 40px;
  height: 88vh
}

#login::after {
  content: "";
  position: absolute;
  // background-image: url("../images/contents/smart_login_V2.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 761px;
  bottom: 0
}

#login .page-content {
  position: relative;
  z-index: 9999;
  padding: 20px
}

#login .panel {
  border-radius: 0px;
  border: none
}

#login .panel .smt-title {
  margin: auto;
  width: 70%;
  position: relative;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #126AA7;
  text-align: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px
}

#login .panel .panel-body {
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 40px
}

#login .panel .smt-footer {
  margin: auto;
  width: 70%;
  position: relative;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #126AA7;
  text-align: center;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px
}

#login .btn-login {
  text-transform: uppercase
}



.sidebar {
  color: #678098;
  background: #fff;
}

.sidebar .nav-link{
  color: #678098;
}

.sidebar .nav-link:hover{
  color: #5b9bd1;
}

.app-header{
  background: #126AA7;
}

.sidebar .nav-link:hover  {
  color: #fff;
}
.sidebar .nav-link:hover .nav-icon {
  color: #fff;
}


.sidebar .nav-link.active:hover .nav-icon {
  color: #20a8d8;
}
.sidebar .nav-link.active:hover{
  color: #5b9bd1;
}

.sidebar .nav-link.active {
  background: #f2f6f9;
  color: #5b9bd1;
}


.text-muted{
  padding: 10px;
}

.smt-title {
  margin: auto;
  width: 70%;
  position: relative;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #126AA7;
  text-align: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 15px;
}

.sidebar-minimized .sidebar .nav-link:hover{
  color: #fff;
}


.card-group .card{
  padding: 0px !important;
}

.card-group .card .card-body{
  margin-top: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

#login-fm  label {
  font-size: 14px !important;
  line-height: 24px;
}

.navbar.bg-primary{
  position: fixed;
  width: 100%;
}



#login-fm  .smt-footer {
  margin: auto;
  width: 70%;
  position: relative;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #126AA7;
  text-align: center;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.op-9 { opacity: 0.9; }
.op-8 { opacity: 0.8; }
.op-7 { opacity: 0.7; }
.op-6 { opacity: 0.6; }
.op-5 { opacity: 0.5; }
.op-4 { opacity: 0.4; }
.op-3 { opacity: 0.3; }
.op-2 { opacity: 0.2; }
.op-1 { opacity: 0.1; }

.btn {
  margin: 0 4px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  font-size: 13px;
}
.dx-datagrid-rowsview .dx-datagrid-content {
  padding-top: 0;
}
.jumbotron {
  background-color: #fafafa;
  border-radius: 20px !important;
  border: 1px solid #DDD;
}
.ant-select-disabled .ant-select-selection {
  background: #DDD;
}
.ant-switch-loading, .ant-switch-disabled {
  opacity: 0.6;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  background-color: #DDD;
}

.card {
  background-color: rgba(#fafafa, 0.7);
}

.ant-collapse {
  background-color: rgba(#fafafa, 0) !important;
}

.header-menu {
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: relative;
  .menu-wrapper {
    max-width: 1360px;
    padding: 0;
    margin: 0 auto;
  }
  .menu-second {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0;
    .image-logo {
      height: 60px;
      cursor: pointer;
      object-fit: contain;
      object-position: 0 5px;
    }
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 0 0 20px;
    @media (max-width: 767px) {
      justify-content: flex-end;
      padding: 0 10px 0 0px;
    }
  }
  .nav-second {
    a {
      text-decoration: none;
    }
    span {
      font-size: 20px;
      color: #000;
    }
    .lang-btn {
      min-width: 120px;
      height: 30px;
      border-radius: 20px;
      border: 1px solid #DDD;
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      align-items: center;
      overflow: hidden;
      .lang-th, .lang-en {
        width: 50%;
        text-align: center;
      }
      .lang-th {
        img {margin-right: 10px;}
        small {font-size: 13px}
        border-right: 1px solid #DDD;
        &.active {
          background-color: #DDD;
          small {font-weight: bold}
        }
      }
      .lang-en {
        img {margin-left: 10px;}
        small {font-size: 13px}
        &.active {
          background-color: #DDD;
          small {font-weight: bold}
        }
      }
    }
    .lang-image {
      height: 20px;
      cursor: pointer;
      object-fit: contain;
      object-position: center;
    }
  }
}

.lang-btn-header {
  min-width: 120px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #DDD;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  align-items: center;
  overflow: hidden;
  background-color: #FFF;
  .lang-th, .lang-en {
    width: 50%;
    text-align: center;
  }
  .lang-th {
    img {margin-right: 10px;}
    small {font-size: 13px}
    border-right: 1px solid #DDD;
    &.active {
      background-color: #DDD;
      small {font-weight: bold}
    }
  }
  .lang-en {
    img {margin-left: 10px;}
    small {font-size: 13px}
    &.active {
      background-color: #DDD;
      small {font-weight: bold}
    }
  }
}
.lang-image {
  height: 20px;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
}

.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 60px);
}

.card-box {
  position: relative;
  width: 580px;
  border-radius: 20px;
  background-color: #FFF;
  padding: 20px;
  .header-title {
    text-align: center;
  }
  .card-box-body {

  }
  .card-box-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.card-login {
  position: relative;
  width: 480px;
  border-radius: 20px;
  background-color: #FFF;
  padding: 20px;
}

.btn-current-customer {
  display: block;
  margin: 0 auto 45px;
  font-size: 18px;
  min-width: 210px;
  height: 60px;
  color: #1a1a1a;
  background: #F5F5F5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border-radius: 10px;
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 0 auto 20px;
  }
}

.btn-new-customer {
  display: block;
  margin: 0 auto 45px;
  font-size: 18px;
  min-width: 210px;
  height: 60px;
  color: #f5f5f5;
  background: #186BB1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border-radius: 10px;
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 0 auto 20px;
  }
}

.btn-back {
  display: inline-block;
  margin: 20px auto 10px;
  padding: 0 30px;
  font-size: 14px;
  height: 40px;
  color: #FFF;
  background: green;
  box-shadow: 0 1px 3px 0 rgba(green, 0.6);
  cursor: pointer;
  border-radius: 10px;
  &:disabled, &[disabled], &.disabled {
    opacity: 0.5;
  }
  &:hover {
    background: darken(green, 5%);
  }
}

.btn-next {
  display: inline-block;
  margin: 30px auto 10px;
  padding: 0 30px;
  font-size: 14px;
  min-width: 150px;
  height: 40px;
  background: linear-gradient(45deg, #F89925 30%, #FDBA48 90%) !important;
  color: #FFF;
  border-color: #f89932 !important;
  cursor: pointer;
  border-radius: 4px;
  &:disabled, &[disabled], &.disabled {
    opacity: 0.5;
  }
  &:hover {
    background: darken(#F89925, 5%);
  }
}

.logo-box {
  width: 100%;
  height: 200px;
  overflow: hidden;
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    object-position: center;
  }
}

@media (max-width: 767px) {
  .reg-text {display: none}
}

#register- {
  padding: 20px;
}

.change-theme .MuiFormControlLabel-label {
  color: #000 !important;
}

.bg-gray {
  background-color: #e8e8e8 !important;
}
.card-body-from {
  padding: 20px 15px !important;
}
.ant-select-dropdown {
  z-index: 3000;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: #156aa7;
}

.btn-pinterest  {
  color: #FFF !important;
}
.btn-css3 {
  color: #FFF !important;
}

a[disabled] {
  opacity: 0.5;
  cursor: no-drop;
}

input.num {
  font-family: sans-serif !important;
}

.ant-popover {
  z-index: 9999;
}

.previewAds {
  .ant-modal-body {
    padding: 0 !important;
  }
}
